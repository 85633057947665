import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LazyLaod from "react-lazyload"
import MapSection from "../sections/About/MapSection"
import {
  SectionPage,
  BreadCrumb,
  LeftPannel,
  RightPannel,
  MarkdownContent,
  Container,
} from "../components/Section"
import {
  HeroSection,
  HeroCover,
  LeftHero,
  RightHero,
  HeroPageTitle,
  HeroDesc,
  HeroFormWrap,
  HeroFormTitle,
  HeroFormBody,
} from "../components/Hero"
import CallToAction from "../components/CallToAction"
import DesignPlan from "../components/DesignPlan"
import AccreditedBusiness from "../components/AccreditedBusiness"
import { graphql, Link } from "gatsby"
import QuickContactForm from "../components/QuickContactForm"
import CardSection from "../sections/Home/CardSection"

function StateTemplate(pageData) {
  const AllProducts = pageData.data.allContentfulProduct.edges.filter(
    edge =>
      edge.node.productCategory &&
      edge.node.productCategory.name &&
      edge.node.productRoofType &&
      edge.node.productRoofType.name
  )

  return (
    <Layout location={pageData.location}>
      <SEO
        title="Metal Buildings Florida - 300+ Steel Building Options in Florida"
        description="Metal Buildings Florida - Our prefab steel buildings are designed to meet Florida’s worst weather conditions. Get your custom steel building with 300+ design options."
      />
      <HeroSection>
        <Container>
          <BreadCrumb className="static">
            <Link to="/">Home</Link> / <span> Steel Building FL</span>
          </BreadCrumb>
          <HeroCover>
            <LeftHero>
              <HeroPageTitle>Metal Buildings Florida</HeroPageTitle>
              <HeroDesc>
                <p>Do you live in sunny Florida? You understand the value of having a high-quality, extremely durable building to protect you from nature's worst blows. Probuilt Steel Buildings offers a highly customized and incredibly durable metal building for Florida residents. These steel buildings are specially conditioned to protect you and your prized possessions from any threat or damage. Metal buildings from Probuilt Steel
                  Buildings are capable of withstanding 200 mph winds without the slightest scratch.</p>
                <p>Call Probuilt Steel Buildings now at <a href="tel:8777541818">(877) 754-1818</a> to ask about FL metal buildings. You can expect customer satisfaction thanks
                  to the best lead times and affordable pricing. Don't forget to ask about free delivery and installation for your metal buildings in Florida.</p>
              </HeroDesc>
            </LeftHero>
            <RightHero>
              <HeroFormWrap>
                <HeroFormTitle>Get In Touch</HeroFormTitle>
                <HeroFormBody><QuickContactForm location={pageData.location} formName="Florida Steel Building Contact Form" /></HeroFormBody>
              </HeroFormWrap>
            </RightHero>
          </HeroCover>
        </Container>
      </HeroSection>

      <LazyLaod>
        <CardSection
          data={{
            title: "Top-Selling Buildings in Florida",
            description: {},
          }}
          productData={AllProducts}
        />
      </LazyLaod>
      <MapSection />

      <SectionPage
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bg="#fff"
      >
        <Container>
          <LeftPannel>
            <MarkdownContent>
              <h2>
                Probuilt Steel Buildings: The Metal Building Manufacturers in
                Florida
              </h2>
              <p>
                Probuilt Steel Buildings’ credentials as the most reliable
                manufacturer of metal buildings in the country are undisputed.
                This American-based company offers customer-friendly policies to
                enhance service and satisfaction.{" "}
              </p>
              <p>
                Here’s why we are the best metal building manufacturer in
                Florida –
              </p>
              <ul>
                <li>
                  Our steel buildings in FL are designed to the highest
                  standards of quality.
                </li>
                <li>
                  FL metal buildings are engineer-certified to withstand wind
                  loads of about 170-200 mph.
                </li>
                <li>
                  The steel components are of the highest quality and 100% made
                  in the USA.
                </li>
                <li>
                  Huge array of customization options available for
                  Florida-certified buildings.
                </li>
                <li>
                  Free delivery and installation services for Floridians.{" "}
                </li>
                <li>
                  Metal building financing available for FL steel buildings.{" "}
                </li>
              </ul>
              <h2>Florida: The Lightning Capital of the US</h2>
              <p>
                According to statistics and as a cursory search on the internet
                will show you, the state of Florida has one of the strictest
                building codes in the whole of the USA. The reason for this is
                in the seismic activity and weather conditions of the area —
                Florida experiences about 1.4 million lightning strike in a
                year, and the surrounding is also prone to incredibly high
                winds. To prevent building collapse and ensure the protection of
                lives and properties, Florida building codes are very strict.
              </p>

              <p>
                The best way to ensure optimal protection during one of
                Florida's hurricanes is to invest in a high-quality steel
                building. Steel, unlike wood, can conduct lighting and pass it
                on to the ground. Steel buildings also have a host of qualities
                such as resistance to fire, highly durable, resistance to mold
                damage, termites, and even 200 mph hurricane winds.
              </p>

              <h2>Best Types of Steel Buildings in Florida</h2>
              <p>
                What are the best types of steel buildings you can find in
                Florida? Because of the strict building codes and requirements,
                a lot of the steel buildings in Florida are certified and of
                very high quality. The expert team at Probuilt Steel Buildings
                designs and builds steel structures to be the strongest
                available.{" "}
              </p>
              <p>
                If you want the best steel buildings in Florida, you should
                consider:
              </p>

              <p>
                <strong>Steel Carports:</strong> these are steel buildings
                designed to protect your vehicles from weather and any damage.
              </p>

              <p>
                <strong>Steel Garages:</strong> professionals build metal
                garages to be functional yet versatile. They will keep your
                belongings — cars, equipment, furniture — safe from damage.
              </p>

              <p>
                <strong>Metal RV Covers:</strong> recreational vehicles are
                costly and deserve the best protection possible. Metal RV covers
                are vital, especially in Florida that experiences hurricanes and
                lightning strikes.
              </p>

              <p>
                <strong>Steel Barns:</strong> professionals build these barns
                for their space and durability. They are incredibly better than
                wood structures and provide optimal protection for your animals
                or farming equipment.
              </p>

              <p>
                <strong>Utility Carports:</strong> strength, durability, and
                versatility are the key here. Professionals build utility
                carports to also serve as storage units. They are the perfect
                example of versatility and functionality in steel buildings.
              </p>

              <p>
                <strong>Storage Buildings:</strong>{" "}
                <a href="https://www.probuiltsteel.com/storage-buildings/">
                  steel storage buildings
                </a>{" "}
                provide a safe place for your properties during rainstorms or
                hurricanes.
              </p>

              <p>
                <strong>Commercial Metal Buildings:</strong> when you talk of
                commercial metal buildings, you're referring to warehouses,
                aircraft hangars, and so on.
              </p>

              <p>
                <strong>Custom Metal Buildings:</strong> no matter what you
                need, experts at Probuilt Steel Buildings can design and build a
                metal facility from the ground up, based on your exact requests.{" "}
              </p>

              <h2>Applications for Steel Buildings FL</h2>
              <p>
                Steel buildings FL are impressively durable and don't get
                affected by the ravages of time. It allows owners to convert the
                structures towards alternative uses. Maybe you want to use it as
                a personal area and then later transform it into a business
                spot. It's fast and easy.{" "}
              </p>
              <p>Alternative applications of metal buildings FL include:</p>

              <p>
                <strong>Storage units:</strong> this is a trendy alternative
                application for steel buildings in Florida. You can easily
                convert your building into a safe place for keeping your
                valuables and other belongings.
              </p>
              <p>
                <strong>Workshop:</strong> you can repurpose FL steel buildings
                into carpentry or metal workshops. They have enough space and
                flexibility to make it happen.
              </p>
              <p>
                <strong>Gym:</strong> this is another alternative application of
                steel buildings in Florida. You only need to add exercise
                equipment and few other structural{" "}
                <a href="https://www.probuiltsteel.com/buying-guide/steel-building-components/">
                  components
                </a>{" "}
                for the conversion to be complete.{" "}
              </p>
              <p>
                <strong>And more!</strong>
              </p>

              <h2>Most Popular Sizes of Metal Buildings FL</h2>
              <p>
                While Probuilt Steel Buildings offers their clients flexibility
                in choosing the size of their metal buildings in Florida, there
                are also custom sizes available for you to get your creative
                juices rolling.{" "}
              </p>

              <p>Some of the popular FL metal buildings sizes are: </p>
              <p>
                <strong>18x20 Steel Building:</strong> this size is popular
                among people who want a single car garage or a garage equipped
                with storage space. This size of building allows for
                customization and is highly durable.{" "}
              </p>

              <p>
                <strong>20x30 Steel Building:</strong> if you're looking for the
                perfect garage to house your vehicles, this is the size to go
                for. Depending on how you position your cars, this metal
                building size could take 2 to 4 cars while also leaving ample
                storage space.
              </p>

              <p>
                <strong>30x40 Steel Building:</strong> people use this building
                size for warehousing purposes and a large storage unit. If you
                have small animals, you can transform the area for agricultural
                purposes.
              </p>

              <p>
                <strong>40x60 Steel Building:</strong> the custom commercial
                metal building sizes start from here. If you want a metal
                building that is large and grounded enough for agricultural
                uses, you should consider a 40x 60 Steel Building. You can use
                the area as an aircraft hangar, commercial building, and so on.
              </p>

              <p>
                <strong>50x100, 60x80, and 60x120 Steel Buildings:</strong>{" "}
                these sizes are perfect for commercial and industrial uses. They
                have a large space for versatile options, from seating to
                warehouse space.{" "}
              </p>

              <p>
                <strong>100x100 and 200x200 Steel Buildings:</strong> if you
                want to go big, go for these sizes. Customers often buy these
                sizes for large-scale commercial and industrial purposes.
              </p>

              <h2>Customization Options for Metal Buildings Florida</h2>
              <p>
                As already mentioned, Probuilt Steel Buildings offers clients an
                array of customization options geared towards making your
                building the perfect fit for you. Customization possibilities
                include flexible roof options --vertical, A-frame horizontal,
                and regular roof choices.
              </p>
              <p>
                You can also select building sizes, anchors, flexible color
                options, steel frame gauges (between the standard 14-gauge and
                the thicker 12-gauge frame, roof panel gauges, certifications,
                and other design components like J-Trims and gables. Call
                Probuilt Steel Buildings at{" "}
                <a href="tel:8777541818">(877) 754-1818</a> to discuss
                customization options with a building specialist. The
                professionals have design experience as well to ensure your
                metal structure matches your property.
              </p>

              <h2>Steel Buildings Florida Prices</h2>
              <p>
                The typical FL metal building from Probuilt Steel Buildings is
                affordable. It can fit any tight budget thanks to its
                budget-friendly options. However, certain factors can drive up
                the price of your metal building. These things include your
                customization choices, delivery location, installation fees, and
                metal building size. Call Probuilt Steel Buildings now at{" "}
                <a href="tel:8777541818">(877) 754-1818</a> to learn more about
                steel buildings Florida prices, and get the best deal possible.
                Ask about flexible{" "}
                <a href="https://www.probuiltsteel.com/buying-guide/financing-process/">
                  metal building financing
                </a>{" "}
                options available, too!{" "}
              </p>

              <h2>Prefabricated Steel Buildings Florida Kits</h2>
              <p>
                If you are the type of person that likes doing projects
                yourself, prefabricated steel building kits from Probuilt Steel
                Buildings are for you. The steel DIY building kits are very
                affordable and easy to install. You'll get everything you need
                to install your metal building in Florida successfully. You also
                get to save money and gain valuable metal building experience.{" "}
              </p>

              <h2>Benefits of Steel Buildings FL</h2>
              <p>
                Steel buildings are better alternatives to wood buildings and
                brick structures. They are usually more durable, stronger, and
                versatile than wooden buildings. Another benefit of steel
                buildings FL is functionality and its easy customization
                quality.{" "}
              </p>

              <p>Further benefits include:</p>
              <ul>
                <li>Affordable investment </li>
                <li>Versatility in space</li>
                <li>Easy-to-customize </li>
                <li>Long-lasting steel</li>
                <li>Low maintenance costs</li>
              </ul>

              <h2>Top-Quality, American-Made Steel Buildings in Florida</h2>
              <p>
                You need durable steel buildings for your residential,
                commercial, and agricultural properties. Whether you need a
                metal garage, steel carport, or some other enclosed space,
                Probuilt Steel Buildings offers 100% customizable buildings in
                Florida. Call Probuilt Steel Buildings at{" "}
                <a href="tel:8777541818">(877) 754-1818</a> to get your
                high-quality metal buildings FL at an affordable rate. With the
                industry's best lead times, you won't wait long for your metal
                structure to arrive. Take advantage of free delivery and
                installation today.
              </p>
            </MarkdownContent>
          </LeftPannel>
          <RightPannel>
            <DesignPlan />
            <AccreditedBusiness />
          </RightPannel>
        </Container>
      </SectionPage>

      <CallToAction />
    </Layout>
  )
}

export default StateTemplate

export const pageQuery = graphql`
  query CityNamesQuery {
    cities: allContentfulCityLanding {
      nodes {
        url
        cityName
      }
    }
    allContentfulProduct(sort: { fields: skuNumber }) {
      edges {
        node {
          id
          senseiProductId
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          description {
            description
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }
  }
`
